/* poppins-200 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/poppins/poppins-v20-latin-ext_latin-200.eot");
  src: local(""), url("../fonts/poppins/poppins-v20-latin-ext_latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-200.woff2") format("woff2"), url("../fonts/poppins/poppins-v20-latin-ext_latin-200.woff") format("woff"), url("../fonts/poppins/poppins-v20-latin-ext_latin-200.ttf") format("truetype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-200.svg#Poppins") format("svg");
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/poppins/poppins-v20-latin-ext_latin-300.eot");
  src: local(""), url("../fonts/poppins/poppins-v20-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300.woff2") format("woff2"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300.woff") format("woff"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300.ttf") format("truetype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300.svg#Poppins") format("svg");
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/poppins/poppins-v20-latin-ext_latin-300italic.eot");
  src: local(""), url("../fonts/poppins/poppins-v20-latin-ext_latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300italic.woff2") format("woff2"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300italic.woff") format("woff"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300italic.ttf") format("truetype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-300italic.svg#Poppins") format("svg");
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/poppins/poppins-v20-latin-ext_latin-regular.eot");
  src: local(""), url("../fonts/poppins/poppins-v20-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-regular.woff2") format("woff2"), url("../fonts/poppins/poppins-v20-latin-ext_latin-regular.woff") format("woff"), url("../fonts/poppins/poppins-v20-latin-ext_latin-regular.ttf") format("truetype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-regular.svg#Poppins") format("svg");
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/poppins/poppins-v20-latin-ext_latin-600.eot");
  src: local(""), url("../fonts/poppins/poppins-v20-latin-ext_latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600.woff2") format("woff2"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600.woff") format("woff"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600.ttf") format("truetype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600.svg#Poppins") format("svg");
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/poppins/poppins-v20-latin-ext_latin-600italic.eot");
  src: local(""), url("../fonts/poppins/poppins-v20-latin-ext_latin-600italic.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600italic.woff2") format("woff2"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600italic.woff") format("woff"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600italic.ttf") format("truetype"), url("../fonts/poppins/poppins-v20-latin-ext_latin-600italic.svg#Poppins") format("svg");
}

/* oswald-500 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/oswald/oswald-v49-latin-ext_latin-500.eot");
  src: local(""), url("../fonts/oswald/oswald-v49-latin-ext_latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/oswald/oswald-v49-latin-ext_latin-500.woff2") format("woff2"), url("../fonts/oswald/oswald-v49-latin-ext_latin-500.woff") format("woff"), url("../fonts/oswald/oswald-v49-latin-ext_latin-500.ttf") format("truetype"), url("../fonts/oswald/oswald-v49-latin-ext_latin-500.svg#Oswald") format("svg");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/material-icons/material-icons.woff2") format("woff2"), url("../fonts/material-icons/material-icons.woff") format("woff");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

html {
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  width: 100%;
  min-width: 320px;
  font-family: "Poppins", sans-serif;
  background-color: #c5c6c9;
  color: #333333;
  font-size: 100%;
  line-height: 1.75;
  overflow-x: hidden;
  word-wrap: break-word;
}

.body_lock-scroll {
  overflow-y: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.body_custom-cursor {
  cursor: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container-fluid {
  padding-left: 120px;
  padding-right: 120px;
}

.container {
  padding-left: 25px;
  padding-right: 25px;
}

.row {
  margin-left: -25px;
  margin-right: -25px;
}

.col, [class*='col-'] {
  padding-left: 25px;
  padding-right: 25px;
}

.no-gutters {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.page-wrapper {
  z-index: 50;
}

@media screen and (max-width: 1400px) {
  .container-fluid {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 991px) {
  .container-fluid, .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .col, [class*='col-'] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.aside-counters__wrapper-item {
  position: relative;
  margin-bottom: 60px;
  margin-top: 60px;
}

.aside-counters__wrapper-item:after {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 0;
  width: 1px;
  background-color: rgba(176, 176, 176, 0.3);
  transform: rotate(30deg);
}

.aside-counters__wrapper-item:nth-of-type(3n):after {
  display: none;
}

@media only screen and (max-width: 767px) {
  .aside-counters__wrapper-item:after {
    transform: none;
    right: 15px;
    left: 15px;
    top: auto;
    bottom: -40px;
    width: auto;
    height: 1px;
  }
  .aside-counters__wrapper-item {
    margin-bottom: 40px;
    margin-top: 40px;
  }
}

.bg-white {
  background-color: #ffffff;
}

.bg-light {
  background-color: #f2f2f2;
}

.bg-light .slider-header-testimonials:before {
  background: linear-gradient(-90deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
}

.bg-light .slider-header-testimonials:after {
  background: linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
}

.bg-light-grey {
  background-color: #f9f9f9;
}

.bg-blue-grey {
  background-color: #d6d7d9;
}

.bg-blue-grey-dark {
  background-color: #c5c6c9;
}

.bg-blue-grey-dark .aside-counters__wrapper-item:after {
  background-color: #b0b0b0;
}

.bg-dark {
  background-color: #181818;
}

.bg-dark-2 {
  background-color: #232323;
}

.bg-black {
  background-color: #333333;
}

.bg-black-0 {
  background-color: #0f0f0f;
}

.bg-black-1 {
  background-color: #121212;
}

.color-white .slider__caption {
  color: #ffffff;
}

.color-white .slider__counter_current {
  color: #ffffff;
}

.color-white .slider__progressbar {
  background-color: #888888;
}

.color-white .slider__progressbar-fill {
  background-color: #ffffff;
}

.color-white .figure-property__headline {
  background-color: #ffffff;
}

.color-white .section-intro__headline {
  background-color: #ffffff;
}

.color-white .section__headline {
  background-color: #ffffff;
}

div[class*='bg-image-'] {
  background-size: cover;
  background-position: center center;
}

.button {
  position: relative;
  display: inline-block;
  padding: 22px 50px;
  border-radius: 100px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
  background-image: none;
}

@media only screen and (max-width: 991px) {
  .button {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.button:focus {
  outline: none;
}

.button:hover, .button:focus {
  opacity: 1;
  transform: translateY(-3px);
}

.button_bordered.button_dark {
  border-color: #181818;
  color: #181818;
}

.button_bordered.button_dark:focus {
  color: #181818;
}

.button_bordered.button_dark:hover {
  color: #181818;
}

.button_bordered.button_light {
  border-color: #f9f9f9;
  color: #f9f9f9;
}

.button_bordered.button_light:focus {
  color: #f9f9f9;
}

.button_bordered.button_light:hover {
  color: #181818;
}

.button_bordered.button_black {
  border-color: #333333;
  color: #333333;
}

.button_bordered.button_black:focus {
  color: #333333;
}

.button_bordered.button_black:hover {
  color: #ffffff;
}

.button_bordered.button_white {
  border-color: #ffffff;
  color: #ffffff;
}

.button_bordered.button_white:focus {
  color: #ffffff;
}

.button_bordered.button_white:hover {
  color: #181818;
  background-color: #ffffff;
}

.button_solid {
  border-color: transparent;
}

.button_solid.button_dark {
  background-color: #181818;
  color: #ffffff;
}

.button_solid.button_dark:focus {
  color: #ffffff;
}

.button_solid.button_dark:hover {
  border-color: #181818;
}

.button_solid.button_light {
  background-color: #f9f9f9;
  color: #181818;
}

.button_solid.button_light:focus {
  color: #181818;
}

.button_solid.button_light:hover {
  border-color: #f9f9f9;
}

.button_solid.button_black {
  background-color: #333333;
  color: #ffffff;
}

.button_solid.button_black:focus {
  color: #ffffff;
}

.button_solid.button_black:hover {
  border-color: #181818;
}

.button_solid.button_white {
  background-color: #ffffff;
  color: #181818;
}

.button_solid.button_white:focus {
  color: #181818;
}

.button_solid.button_white:hover {
  border-color: #181818;
  background-color: #181818;
  color: #ffffff;
}

.button_fullwidth {
  width: 100%;
}

.button_small {
  padding: 16px 32px;
}

.comment-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comment-list > li {
  padding-top: 30px;
  padding-bottom: 30px;
}

.comment-list > li ol.children {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 4%;
}

.comment-list > li ol.children li {
  padding-top: 30px;
  padding-bottom: 30px;
}

.comment-list > li ol.children li:last-child {
  padding-bottom: 0;
}

.comment-list > li:not(:last-child) {
  border-bottom: 1px solid rgba(176, 176, 176, 0.3);
}

.comment-list > ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.comment-author {
  display: inline-block;
}

.comment-author .avatar {
  position: relative;
  width: 100%;
  max-width: 100px;
  max-height: 100px;
  border-radius: 100%;
  margin-right: 1.5em;
}

@media only screen and (max-width: 991px) {
  .comment-author .avatar {
    width: 50px;
    height: 50px;
  }
}

.comment-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.comment-meta a {
  background-image: none;
}

@media only screen and (max-width: 991px) {
  .comment-meta {
    flex-wrap: wrap;
  }
}

.comment-metadata {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 10px;
}

@media only screen and (max-width: 991px) {
  .comment-metadata {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.comment-metadata a:not(.comment-edit-link) {
  color: #b0b0b0;
}

.comment-metadata a:not(.comment-edit-link):hover {
  color: var(--color-accent-secondary);
}

.comment-metadata a {
  border-bottom: none !important;
}

@media only screen and (max-width: 991px) {
  .comment-metadata {
    margin-top: 5px;
    margin-left: 0;
  }
}

.comment-body {
  display: flex;
}

.comment-body .fn {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.44;
  color: #181818;
}

.comment-body .fn a {
  border-bottom: none;
}

.comment-author {
  flex: 0 0 auto;
}

.comment-content {
  width: 100%;
}

.reply {
  display: inline-block;
}

.comment-reply-link {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-bottom: none !important;
  margin-right: 24px;
  background-image: none;
}

@media only screen and (max-width: 991px) {
  .comment-reply-link {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.comment-reply-link:before {
  content: '\f112';
  font-family: 'FontAwesome';
  text-transform: none;
  margin-right: 8px;
}

.comment-respond {
  margin-top: 10px;
}

.comment-edit-link {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color-accent-primary);
  border-bottom: none !important;
  background-image: none;
}

@media only screen and (max-width: 991px) {
  .comment-edit-link {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.comment-edit-link:before {
  content: '\f044';
  font-family: 'FontAwesome';
  text-transform: none;
  margin-right: 6px;
}

.counter {
  text-align: center;
}

.counter__number {
  font-size: 68px;
  font-weight: 200;
  line-height: 1;
}

.counter__label {
  margin-top: 5px;
}

@media screen and (max-width: 1400px) {
  .counter__number {
    font-size: 54px;
  }
}

@media screen and (max-width: 991px) {
  .counter__number {
    font-size: 43px;
  }
}

.cursor {
  position: fixed;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
  display: none;
}

.cursor__follower {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.cursor__follower svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cursor__follower svg #inner, .cursor__follower svg #outter {
  fill: none;
  stroke-linecap: butt;
}

.cursor__follower svg #inner {
  stroke-width: 2px;
  stroke: #888888;
  opacity: .6;
}

.cursor__follower svg #outter {
  stroke-width: 4px;
  stroke: #c5c6c9;
}

.cursor-progress {
  cursor: progress;
}

.cursor-progress * {
  cursor: progress;
}

.figure-image {
  text-align: center;
}

.figure-image img {
  width: 100%;
}

.figure-image h5 {
  display: block;
  width: 100%;
  margin-top: 1em;
}

.figure-logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
  height: 330px;
  padding: 30px;
}

.figure-logo img {
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.figure-logo:hover .figure-logo__description {
  transform: scaleY(1);
}

.figure-logo:hover .figure-logo__description p {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
  transition-delay: 0.15s;
}

.figure-logo:hover .figure-logo__description .figure-logo__line {
  transition-delay: 0.2s;
  transform: scaleX(1);
}

.figure-logo:hover img {
  transform: translateY(-15px);
}

.figure-logo__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181818;
  color: #ffffff;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: scaleY(0);
  transform-origin: bottom center;
}

.figure-logo__description p {
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.figure-logo__line {
  display: inline-block;
  width: 30px;
  height: 1px;
  background-color: #ffffff;
  transform: scaleX(0);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@media screen and (max-width: 1200px) {
  .figure-logo {
    width: 270px;
    height: 270px;
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .figure-logo {
    width: 100%;
  }
}

.figure-member {
  position: relative;
}

.figure-member_has-social:hover .figure-member__wrapper-img img {
  transform: scale(1.1);
}

.figure-member_has-social:hover .figure-member__overlay {
  transform: scaleX(1);
}

.figure-member_has-social:hover .figure-member__social li {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(1) {
  transition-delay: 100ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(2) {
  transition-delay: 150ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(3) {
  transition-delay: 200ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(4) {
  transition-delay: 250ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(5) {
  transition-delay: 300ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(6) {
  transition-delay: 350ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(7) {
  transition-delay: 400ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(8) {
  transition-delay: 450ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(9) {
  transition-delay: 500ms;
}

.figure-member_has-social:hover .figure-member__social li:nth-child(10) {
  transition-delay: 550ms;
}

.figure-member__content {
  text-align: right;
  margin-top: 1em;
}

.figure-member__position {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  top: -7px;
  right: calc(100% + 25px);
  color: #888888;
  transform: rotate(-90deg);
  transform-origin: right center;
  text-align: right;
  white-space: nowrap;
}

@media only screen and (max-width: 991px) {
  .figure-member__position {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.figure-member__wrapper-img {
  position: relative;
  overflow: hidden;
}

.figure-member__wrapper-img img {
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: center center;
}

.figure-member__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #181818;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: scaleX(0);
  transform-origin: right center;
}

.figure-member__social {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 50;
  color: #ffffff;
}

.figure-member__social li {
  display: block;
  transform: translateX(30px);
  transition: all 0.6s ease;
  opacity: 0;
  visibility: hidden;
  margin-right: 0;
  margin-bottom: 15px;
}

@media screen and (max-width: 1400px) {
  .figure-member__position {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .figure-member {
    text-align: center;
  }
  .figure-member__content {
    text-align: center;
    margin-top: 20px;
  }
  .figure-member__position {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: none;
    text-align: center;
    margin-bottom: 10px;
  }
}

.figure-portfolio {
  position: relative;
  display: block;
  background-image: none;
}

.figure-portfolio:hover, .figure-portfolio:focus {
  opacity: 1;
}

.figure-portfolio__content {
  text-align: right;
}

.figure-portfolio__content h3 {
  margin-top: 30px;
  margin-bottom: 0;
  color: #333333;
  font-weight: 600;
}

.figure-portfolio__counter {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  position: absolute;
  top: -30px;
  left: -30px;
  color: #888888;
  transform: rotate(-90deg);
  transform-origin: left center;
}

@media screen and (max-width: 1400px) {
  .figure-portfolio__counter {
    font-size: 12px;
    top: -20px;
    left: -20px;
  }
  .figure-portfolio__content h3 {
    margin-top: 20px;
  }
}

@media screen and (max-width: 991px) {
  .figure-portfolio__counter {
    top: -13px;
    left: -13px;
  }
  .figure-portfolio__content {
    text-align: center;
  }
  .figure-portfolio__content h3 {
    margin-top: 15px;
  }
}

.figure-pricing {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px;
  position: relative;
  height: 100%;
  z-index: 50;
  background-color: #f2f2f2;
}

.figure-pricing_featured {
  background-color: #181818;
  color: #ffffff;
}

.figure-pricing_featured .figure-pricing__header, .figure-pricing_featured .button {
  border-color: #ffffff;
}

.figure-pricing__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid #181818;
  padding-bottom: 16px;
}

.figure-pricing__header h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.figure-pricing__amount {
  font-size: 68px;
  line-height: 1;
  font-weight: 200;
  position: relative;
  display: inline-block;
}

.figure-pricing__cost-sign {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;
}

.figure-pricing__cost-sign_before {
  margin-right: 5px;
}

.figure-pricing__cost-sign_after {
  margin-left: 5px;
}

.figure-pricing__features {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 30px;
}

.figure-pricing__feature {
  display: block;
  margin-top: 16px;
  margin-bottom: 16px;
}

.figure-pricing__wrapper-button {
  margin-top: auto;
}

@media screen and (max-width: 1200px) {
  .figure-pricing {
    padding: 30px;
  }
  .figure-pricing__amount {
    font-size: 53px;
  }
  .figure-pricing__cost {
    margin-bottom: -5px;
  }
}

.figure-property {
  width: 100%;
}

.figure-property__headline {
  width: 100%;
  height: 2px;
  background-color: #181818;
  margin-bottom: 16px;
}

.figure-property__wrapper-heading_bold h6 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
}

.figure-property__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.figure-property__item {
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
}

@media screen and (max-width: 991px) {
  .figure-property__wrapper-heading_bold h6 {
    font-size: 16px;
  }
}

.footer {
  position: relative;
  background-color: #0f0f0f;
  color: #888888;
  font-size: 16px;
}

.footer a {
  color: #ffffff;
}

.footer__area-primary {
  padding-top: 160px;
  padding-bottom: 160px;
}

.footer__area-primary .footer__col:nth-child(1) {
  text-align: left;
}

.footer__area-primary .footer__col:nth-child(2) {
  text-align: center;
}

.footer__area-primary .footer__col:nth-child(3) {
  text-align: right;
}

.footer__area-secondary {
  padding-top: 50px;
  padding-bottom: 50px;
  border-top: 1px solid rgba(214, 215, 217, 0.2);
}

.footer__area-secondary .footer__col:nth-child(1) {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .footer__area-primary {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .footer__area-secondary {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .footer__area-primary {
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .footer__area-primary .footer__col {
    margin-bottom: 30px;
  }
  .footer__area-primary .footer__col:nth-of-type(1) {
    text-align: center;
  }
  .footer__area-primary .footer__col:nth-of-type(2) {
    text-align: center;
  }
  .footer__area-primary .footer__col:nth-of-type(3) {
    text-align: center;
  }
  .footer__area-primary .footer__col:last-of-type {
    margin-bottom: 0;
  }
  .footer__area-secondary {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.form {
  width: 100%;
}

.form__submit {
  margin-top: 10px;
}

.form__col {
  margin-top: 15px;
  margin-bottom: 15px;
}

.form__col_submit {
  margin-top: 30px;
  margin-bottom: 0;
}

.form__error, span.wpcf7-not-valid-tip {
  display: block;
  font-size: 12px;
  color: red;
  text-align: left;
  margin-top: 4px;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  border-color: red;
  padding: 15px;
  margin: 1.5em 0 0;
  display: none !important;
}

@media only screen and (max-width: 992px) {
  .form__col_submit {
    text-align: left;
  }
}

.gmap {
  width: 100%;
  height: 100%;
  background-color: #c5c6c9;
}

.gmap__container {
  width: 100%;
  height: 100%;
  height: 900px;
}

@media only screen and (max-width: 991px) {
  .gmap__container {
    height: 600px;
    max-height: 120vh;
  }
}

.grid__item_no-fancy:nth-of-type(3) {
  margin-top: 0 !important;
}

.grid_50 {
  margin-left: -50px;
  margin-right: -50px;
}

.grid__item_50 {
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 100px;
}

.grid__item_50:nth-of-type(3) {
  margin-top: 200px;
}

.grid__item_50:last-of-type {
  margin-bottom: 0;
}

.grid_100 {
  margin-left: -100px;
  margin-right: -100px;
}

.grid__item_100 {
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 200px;
}

.grid__item_100:nth-of-type(3) {
  margin-top: 200px;
}

.grid__item_100:last-of-type {
  margin-bottom: 0;
}

.grid__item_120 {
  padding-left: 120px;
  padding-right: 120px;
  margin-bottom: 240px;
}

.grid__item_120:nth-of-type(3) {
  margin-top: 240px;
}

.grid__item_120:last-of-type {
  margin-bottom: 0;
}

.grid_team {
  margin-left: -60px;
  margin-right: -60px;
}

.grid__item_team {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 100px;
}

.grid__item_team:nth-of-type(3) {
  margin-top: 100px;
}

.grid__item_team:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 1400px) {
  .grid__item_120 {
    padding-left: 80px;
    padding-right: 80px;
    margin-bottom: 160px;
  }
  .grid__item_120:nth-of-type(3) {
    margin-top: 160px;
  }
  .grid_100 {
    margin-left: -50px;
    margin-right: -50px;
  }
}

@media screen and (max-width: 1200px) {
  .grid_120 {
    margin-left: -50px;
    margin-right: -50px;
  }
  .grid__item_120 {
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 100px;
  }
  .grid__item_120:nth-of-type(3) {
    margin-top: 100px;
  }
}

@media screen and (max-width: 991px) {
  .grid_120, .grid_100, .grid_50 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .grid__item_120, .grid__item_100, .grid__item_50 {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 40px;
  }
  .grid__item_120:nth-of-type(3), .grid__item_100:nth-of-type(3), .grid__item_50:nth-of-type(3) {
    margin-top: 0;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  padding-top: 70px;
}

.header_white .logo {
  color: #ffffff;
}

.header_white .logo:focus {
  color: #ffffff;
}

.header_white .header__burger-line {
  background-color: #ffffff;
}

.header_white .menu > li a {
  color: #ffffff;
}

.header_white .menu > li:not(.menu-item-has-children) > a:before {
  background-color: #ffffff;
}

.header_white .menu .sub-menu {
  background-color: #181818;
}

.header_fixed {
  position: fixed;
}

.header__burger {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 600;
}

.header__burger:not(.header__burger_opened):hover .header__burger-line:nth-child(1) {
  transform: scaleX(0.75);
}

.header__burger:not(.header__burger_opened):hover .header__burger-line:nth-child(2) {
  transform: scaleX(1.25);
}

.header__burger_opened .header__burger-line {
  background-color: #ffffff;
}

.header__burger_opened .header__burger-line:nth-child(1) {
  transform: scaleX(0.75);
}

.header__burger_opened .header__burger-line:nth-child(2) {
  transform: scaleX(1.25);
}

.header__burger_opened:hover .header__burger-line:nth-child(1) {
  transform: scaleX(1);
}

.header__burger_opened:hover .header__burger-line:nth-child(2) {
  transform: scaleX(1);
}

.header__burger-line {
  display: block;
  width: 100%;
  background-color: #333333;
  height: 2px;
  margin: 4px 0;
  transition: all 0.6s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: right center;
}

.header__burger-line:nth-child(1) {
  max-width: 40px;
}

.header__burger-line:nth-child(2) {
  max-width: 30px;
}

.header__wrapper-overlay-menu {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  visibility: hidden;
}

.header__wrapper-menu {
  display: flex;
  align-items: flex-end;
  padding: 120px 120px 40px;
  width: 100%;
}

.header__wrapper-overlay-widgets {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding: 120px 120px 60px;
}

.header__wrapper-property {
  margin-bottom: 50px;
  font-size: 16px;
}

.header__wrapper-property:last-of-type {
  margin-bottom: 0;
}

.header__overlay-menu-back {
  position: absolute;
  top: 70px;
  left: 110px;
  font-size: 45px !important;
  color: #ffffff;
  z-index: 600;
  cursor: pointer;
}

.header__col-left {
  position: relative;
}

.header_lock-submenus .menu .sub-menu {
  opacity: 0 !important;
  visibility: hidden !important;
  transform: translate(0, 10px) !important;
}

@media screen and (max-width: 1400px) {
  .header {
    padding-top: 50px;
  }
  .header__wrapper-menu {
    padding: 80px 80px 40px;
  }
  .header__wrapper-overlay-widgets {
    padding: 80px 80px 60px;
  }
  .header__overlay-menu-back {
    top: 50px;
    left: 70px;
  }
}

@media screen and (max-width: 1200px) {
  .header {
    padding-top: 40px;
  }
  .header__wrapper-menu {
    padding: 50px 50px 30px;
  }
  .header__wrapper-overlay-widgets {
    padding: 50px 50px 40px;
  }
  .header__wrapper-property {
    margin-bottom: 40px;
  }
  .header__overlay-menu-back {
    top: 40px;
    left: 40px;
  }
}

@media screen and (max-width: 991px) {
  .header__burger-line {
    margin: 3px 0;
  }
  .header__burger-line:nth-child(1) {
    max-width: 30px;
  }
  .header__burger-line:nth-child(2) {
    max-width: 20px;
  }
  .header__wrapper-menu {
    padding: 0;
    width: 100%;
    padding: 50px 0;
  }
  .header__wrapper-overlay-menu {
    flex-wrap: wrap;
    text-align: center;
    padding: 50px 30px;
  }
  .header__wrapper-overlay-widgets {
    border-top: 1px solid rgba(214, 215, 217, 0.2);
    background-color: initial;
    padding: 50px 0;
  }
  .header__overlay-menu-back {
    top: 25px;
    left: 25px;
    font-size: 33px !important;
  }
}

@media screen and (max-width: 991px) {
  .header {
    padding-top: 20px;
  }
}

.honung {
  display: none;
}

.input-float {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 0;
}

.input-float__label {
  position: absolute;
  top: 10px;
  left: 0;
  margin: auto;
  display: block;
  font-size: 16px;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform-origin: left center;
  cursor: text;
  color: #888888;
}

.input-float__input {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(100, 100, 100, 0.3);
  border-top: none;
  border-right: none;
  border-left: none;
  outline: none;
  padding: 12px 0 8px;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background-color: transparent;
  border-radius: 0;
  color: inherit;
}

.input-float__input_textarea {
  resize: none;
  min-height: 200px;
}

.input-float__input_focused + .input-float__label, .input-float__input_not-empty + .input-float__label {
  transform: scale(0.8571) translateY(-1.7rem);
}

.input-float__input_not-empty {
  border-color: rgba(100, 100, 100, 0.3);
}

.input-search__input {
  padding-right: 30px;
}

.input-search__submit {
  display: inline-block;
  font-size: 18px;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: transparent;
  border: none;
  color: #b0b0b0;
  outline: none;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  cursor: pointer;
}

.input-search__submit:hover {
  color: #181818;
}

.input-search__submit:focus {
  outline: none;
}

.lazy {
  background-color: #ffffff;
  animation-duration: 0.9s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

.lazy__img:not(img) {
  background-size: cover;
  background-position: center center;
}

.lazy-bg {
  width: 100%;
  height: 100%;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  to {
    opacity: .3;
  }
}

.logo {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333333;
}

a.logo:focus {
  color: #333333;
  opacity: 1;
}

a.logo {
  background-image: none;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  word-wrap: normal;
  text-align: right;
  display: inline-block;
  font-size: 14px;
}

.menu > li {
  display: inline-block;
}

.menu > li:not(:last-child) {
  margin-right: 17px;
}

.menu > li a {
  display: block;
  background-image: none;
  padding: 7px 15px;
}

.menu > li.menu-item-has-children > a:after {
  content: '\f107';
  font-family: 'FontAwesome';
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.menu > li:not(.menu-item-has-children) a {
  position: relative;
}

.menu > li:not(.menu-item-has-children) a:hover:before {
  left: 15px;
  width: calc(100% - 30px);
}

.menu > li:not(.menu-item-has-children) a:before {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #181818;
  bottom: 5px;
  right: 15px;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.menu .menu-item-has-children {
  position: relative;
}

.menu .menu-item-has-children > a:hover ~ ul {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.menu .sub-menu {
  position: absolute;
  top: 100%;
  right: 17px;
  border-bottom: 4px solid #181818;
  background-color: #ffffff;
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 15px 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translate(0, 10px);
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.02);
}

.menu .sub-menu > li {
  display: block;
  white-space: nowrap;
}

.menu .sub-menu > li a {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background-image: none;
}

.menu .sub-menu > li.menu-item-has-children > a:after {
  content: '\f105';
  font-family: 'FontAwesome';
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  padding-left: 15px;
}

.menu .sub-menu ul {
  top: 0;
  right: calc(100% + 1px);
  transform: translate(10px, 0);
}

.menu .sub-menu:hover {
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
}

.menu-overlay {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 700px;
}

.menu-overlay a {
  background-image: none;
  transition: opacity 0.6 ease;
}

.menu-overlay a.selected {
  opacity: 1 !important;
}

.menu-overlay > li {
  display: block;
}

.menu-overlay > li > a {
  display: block;
  font-size: 54px;
  line-height: 2;
  font-weight: 600;
  color: #ffffff;
}

.menu-overlay > li > a span {
  display: inline-block;
  position: relative;
}

.menu-overlay > li:not(.menu-item-has-children) > a span {
  display: inline-block;
  position: relative;
}

.menu-overlay_hover a {
  opacity: .2 !important;
}

.menu-overlay_hover a:hover, .menu-overlay_hover a:focus {
  opacity: 1 !important;
}

.menu-overlay .sub-menu {
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  z-index: 100;
}

.menu-overlay .sub-menu > li {
  display: block;
}

.menu-overlay .sub-menu > li > a {
  display: block;
  position: relative;
  font-size: 33px;
  line-height: 2.25;
  font-weight: 600;
  color: #ffffff;
}

.menu-overlay .sub-menu > li > a span {
  position: relative;
  display: inline-block;
}

@media screen and (max-width: 1400px) {
  .menu-overlay > li > a {
    font-size: 43px;
    line-height: 2;
  }
  .menu-overlay .sub-menu > li > a {
    font-size: 26px;
    line-height: 2.33;
  }
}

@media screen and (max-width: 991px) {
  .menu-overlay {
    max-width: 100%;
  }
  .menu-overlay > li > a {
    font-size: 24px;
    line-height: 2.5;
  }
  .menu-overlay .sub-menu > li > a {
    font-size: 19px;
    line-height: 2.33;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
}

.overlay_dark {
  background-color: #181818;
  opacity: .8;
  mix-blend-mode: multiply;
}

.overlay_black {
  background-color: #333333;
  opacity: .8;
}

.overlay_white {
  background-color: #ffffff;
  opacity: .95;
}

.overlay_white-solid {
  background-color: #ffffff;
  opacity: 1;
}

.art-parallax, [data-art-parallax] {
  position: relative;
  overflow: hidden;
}

.art-parallax > img, [data-art-parallax] > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.art-parallax__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.post__content h2, .post__content h3, .post__content h4 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.post__content h5, .post__content h6 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.post__content > *:first-child {
  margin-top: 0;
}

.post__media {
  margin-bottom: 2em;
}

.post__tags {
  border-top: 2px solid #181818;
  margin-top: 50px;
  padding-top: 20px;
}

.post__comments {
  margin-top: 100px;
}

.post-preview__wrapper-content > *:first-child {
  margin-top: 0;
}

.post-preview__date {
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.post-preview__header a, .post-preview__media a, .post-preview__date a {
  background-image: none;
}

.post-preview__header {
  border-bottom: 1px solid rgba(176, 176, 176, 0.3);
  margin-bottom: 2em;
}

.post-preview__header h2 {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.post-preview__categories {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.post-preview__categories li {
  display: inline-block;
}

.post-preview__meta {
  margin-bottom: 1em;
}

.post-preview__wrapper-meta {
  margin-top: 5px;
}

.post-preview__wrapper-readmore {
  text-align: right;
  margin-top: 2em;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  z-index: 400;
}

.preloader__curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preloader__curtain_black {
  background-color: #1f1f1f;
}

.preloader__curtain_white {
  background-color: #f2f2f2;
}

.project-backgrounds {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #232323;
  opacity: 0;
  visibility: hidden;
}

.project-backgrounds__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}

.project-backgrounds__background.active, .project-backgrounds__background.selected {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.project-backgrounds__overlay {
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 0 !important;
}

.smooth-scroll {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

[data-os-animation] {
  opacity: 0;
  visibility: hidden;
}

[data-os-animation] .split-text__line a {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 100px);
}

[data-os-animation="animated"] .split-text__line a {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 0px, #888888 0px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 100px);
}

.section {
  position: relative;
}

.section-fullheight {
  display: flex;
}

.section-fullheight__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 200px;
  padding-bottom: 200px;
}

.section_pt-large {
  padding-top: 300px;
}

.section_pt {
  padding-top: 200px;
}

.section_pt-small {
  padding-top: 100px;
}

.section_pb {
  padding-bottom: 200px;
}

.section_pb-small {
  padding-bottom: 100px;
}

.section_pb-large {
  padding-bottom: 300px;
}

.section_mt-large {
  margin-top: 300px;
}

.section_mt {
  margin-top: 200px;
}

.section_mt-small {
  margin-top: 100px;
}

.section_mb {
  margin-bottom: 200px;
}

.section_mb-small {
  margin-bottom: 100px;
}

.section_mb-large {
  margin-bottom: 300px;
}

.section__header h2 {
  margin-bottom: 0.75em;
}

.section__header h3:last-of-type {
  margin-bottom: 0;
}

.section__headline {
  display: block;
  width: 80px;
  height: 1px;
  background-color: #181818;
  margin-bottom: 10px;
}

.section_h-100 {
  height: 100px;
  max-height: 100vh;
}

.section_h-200 {
  height: 200px;
  max-height: 100vh;
}

.section_h-300 {
  height: 300px;
  max-height: 100vh;
}

.section_h-400 {
  height: 400px;
  max-height: 100vh;
}

.section_h-500 {
  height: 500px;
  max-height: 100vh;
}

.section_h-600 {
  height: 600px;
  max-height: 100vh;
}

.section_h-700 {
  height: 700px;
  max-height: 100vh;
}

.section_h-800 {
  height: 800px;
  max-height: 100vh;
}

.section_h-900 {
  height: 900px;
  max-height: 100vh;
}

.section_h-100vh {
  height: 100vh;
}

.section__content {
  margin-top: 50px;
}

.section_w-container-right {
  max-width: 100%;
  width: calc( 100% - ( (100vw - 1140px) / 2 ) - 25px);
  margin-left: auto;
  text-align: left;
}

.section_w-container-left {
  max-width: 100%;
  width: calc( 100% - ( (100vw - 1140px) / 2 ) - 25px);
  margin-right: auto;
  text-align: right;
}

@media screen and (max-width: 1200px) {
  .section_pt-large {
    padding-top: 240px;
  }
  .section_pt {
    padding-top: 140px;
  }
  .section_pt-small {
    padding-top: 70px;
  }
  .section_pb {
    padding-bottom: 140px;
  }
  .section_pb-small {
    padding-bottom: 70px;
  }
  .section_pb-large {
    padding-bottom: 240px;
  }
  .section_mt-large {
    margin-top: 240px;
  }
  .section_mt {
    margin-top: 140px;
  }
  .section_mt-small {
    margin-top: 70px;
  }
  .section_mb {
    margin-bottom: 140px;
  }
  .section_mb-small {
    margin-bottom: 70px;
  }
  .section_mb-large {
    margin-bottom: 240px;
  }
  .section-fullheight__inner {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .section__headline {
    max-width: 80px;
  }
  .section_w-container-right, .section_w-container-left {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .section_pt-large {
    padding-top: 120px;
  }
  .section_pt {
    padding-top: 100px;
  }
  .section_pt-small {
    padding-top: 50px;
  }
  .section_pb {
    padding-bottom: 100px;
  }
  .section_pb-small {
    padding-bottom: 50px;
  }
  .section_pb-large {
    padding-bottom: 120px;
  }
  .section_mt-large {
    margin-top: 120px;
  }
  .section_mt {
    margin-top: 100px;
  }
  .section_mt-small {
    margin-top: 50px;
  }
  .section_mb {
    margin-bottom: 100px;
  }
  .section_mb-small {
    margin-bottom: 50px;
  }
  .section_mb-large {
    margin-bottom: 120px;
  }
  .section-fullheight__inner {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .section__content {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) {
  .section_pt-large {
    padding-top: 100px;
  }
  .section_pt {
    padding-top: 80px;
  }
  .section_pt-small {
    padding-top: 40px;
  }
  .section_pb {
    padding-bottom: 80px;
  }
  .section_pb-small {
    padding-bottom: 40px;
  }
  .section_pb-large {
    padding-bottom: 100px;
  }
  .section_mt-large {
    margin-top: 100px;
  }
  .section_mt {
    margin-top: 80px;
  }
  .section_mt-small {
    margin-top: 40px;
  }
  .section_mb {
    margin-bottom: 80px;
  }
  .section_mb-small {
    margin-bottom: 40px;
  }
  .section_mb-large {
    margin-bottom: 100px;
  }
  .section-fullheight__inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section__headline {
    max-width: 50px;
  }
  .section_h-100 {
    max-height: 70vh;
  }
  .section_h-200 {
    max-height: 70vh;
  }
  .section_h-300 {
    max-height: 70vh;
  }
  .section_h-400 {
    max-height: 70vh;
  }
  .section_h-500 {
    max-height: 70vh;
  }
  .section_h-600 {
    max-height: 70vh;
  }
  .section_h-700 {
    max-height: 70vh;
  }
  .section_h-800 {
    max-height: 70vh;
  }
  .section_h-900 {
    max-height: 70vh;
  }
}

.section-404__header p {
  margin-top: 3em;
}

.section-404__wrapper-button {
  margin-top: 30px;
}

.section-404__big {
  font-size: 300px;
  font-weight: bold;
  line-height: 1;
  opacity: .05;
  transform: rotate(90deg);
  transform-origin: right center;
  white-space: nowrap;
  position: absolute;
  top: calc(100% - 240px);
  right: 240px;
}

@media screen and (max-width: 1400px) {
  .section-404__big {
    top: calc(100% - 180px);
    right: 180px;
  }
}

@media screen and (max-width: 1200px) {
  .section-404__big {
    font-size: 200px;
    top: calc(100% - 120px);
    right: 120px;
  }
}

@media screen and (max-width: 991px) {
  .section-404 {
    text-align: center;
  }
  .section-404 .section__headline {
    margin-left: auto;
    margin-right: auto;
  }
  .section-404__big {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    font-size: 150px;
  }
}

.section-blog__wrapper-post {
  margin-bottom: 100px;
}

.section-blog__wrapper-post:last-child {
  margin-bottom: 0;
}

.section-cta__wrapper-button {
  margin-top: 35px;
}

.section-cta__headline {
  width: 1px;
  height: 100px;
  background-color: #181818;
  margin-bottom: 55px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 991px) {
  .section-cta__wrapper-button {
    margin-top: 25px;
  }
}

.section-composition {
  display: flex;
}

.section-composition__image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 200px 120px;
  flex: 1 1 auto;
}

.section-composition__content {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  max-width: 640px;
  flex: 1 0 33vw;
  padding: 200px 120px;
}

.section-composition__content h6 {
  font-size: 20px;
}

.section-composition__counter {
  align-self: flex-start;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

@media screen and (max-width: 1400px) {
  .section-composition__image, .section-composition__content {
    padding: 120px 80px;
  }
  .section-composition__counter {
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .section-composition__image, .section-composition__content {
    padding: 80px 50px;
  }
}

@media screen and (max-width: 991px) {
  .section-composition {
    flex-direction: column;
    justify-content: center;
  }
  .section-composition__content {
    align-items: center;
    justify-content: center;
    max-width: 100%;
    flex: 1 0 100%;
    order: -1;
    padding: 80px 30px 0;
    background-color: initial;
  }
  .section-composition__image {
    background-color: initial;
  }
  .section-composition__counter {
    align-self: unset;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  .section-composition__image {
    padding: 30px 30px 80px;
  }
  .section-composition__content h6 {
    font-size: 16px;
  }
}

.section-content__inner > *:last-child {
  margin-bottom: 0;
}

.section-content__main-content {
  margin-top: 100px;
  margin-bottom: -50px;
}

.section-content__wrapper-item {
  margin-bottom: 50px;
}

.section-content__main-content_no-header {
  margin-top: 0 !important;
}

.section-content__inner_header {
  margin-bottom: 1em;
}

@media screen and (max-width: 991px) {
  .section-content__main-content {
    margin-top: 60px;
    margin-bottom: -30px;
  }
  .section-content__wrapper-item {
    margin-bottom: 30px;
  }
}

.section-figures .section-figures__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 200px 120px;
}

.section-figures .section-figures__item h5 {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 0;
}

.section-figures .section-figures__item_no-paddings {
  padding: 0;
}

@media screen and (max-width: 1400px) {
  .section-figures .section-figures__item:not(.section-figures__item_no-paddings) {
    padding: 120px 80px;
  }
}

@media screen and (max-width: 1200px) {
  .section-figures .section-figures__item:not(.section-figures__item_no-paddings) {
    padding: 80px 50px;
  }
}

.section-fullscreen-slider.color-white .slider__dot:before {
  background-color: #b0b0b0;
}

.section-fullscreen-slider.color-white .slider__dot:after {
  border-color: #ffffff;
}

.section-fullscreen-slider.color-white .slider__dot_active:before {
  background-color: #ffffff;
  border-color: #ffffff;
}

.section-fullscreen-slider__inner {
  padding-top: 0;
  padding-bottom: 0;
}

.section-image__content {
  margin-top: 40px;
}

.section-image h5 {
  display: block;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 0;
}

.section-intro.text-center .section-intro__header, .section-intro.text-center .section-intro__headline {
  margin-left: auto;
  margin-right: auto;
}

.section-intro__header {
  max-width: 1200px;
}

.section-intro__header h1 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 43px;
  line-height: 1.67;
}

.section-intro__headline {
  width: 100%;
  max-width: 120px;
  height: 1px;
  background-color: #333333;
}

@media screen and (max-width: 1200px) {
  .section-intro__header h1 {
    font-size: 33px;
  }
  .section-intro__headline {
    max-width: 80px;
  }
}

@media screen and (max-width: 991px) {
  .section-intro__header h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 767px) {
  .section-intro__header h1 {
    margin-top: 40px;
    font-size: 21px;
  }
  .section-intro__headline {
    max-width: 50px;
  }
}

.section-map__wrapper-form {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80px;
  right: 120px;
  bottom: 80px;
  padding: 100px 80px;
}

@media screen and (max-width: 1400px) {
  .section-map__wrapper-form {
    right: 80px;
    padding: 80px 60px;
  }
}

@media screen and (max-width: 1200px) {
  .section-map__wrapper-form {
    right: 50px;
  }
}

@media screen and (max-width: 991px) {
  .section-map__wrapper-form {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    padding: 80px 30px;
  }
}

.section-masthead__header {
  max-width: 960px;
}

.section-masthead__header h1 {
  margin-top: 1.75em;
  margin-bottom: 0.5em;
}

.section-masthead__header h2 {
  margin-top: 100px;
}

.section-masthead_fullheight h1 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

.section-masthead_fullheight-halfscreen .section-masthead__background {
  position: relative;
  min-height: 100vh;
}

.section-masthead_fullheight-halfscreen-reverse {
  flex-direction: row-reverse;
}

.section-masthead__wrapper-property {
  margin-right: 70px;
  margin-bottom: 70px;
}

.section-masthead__wrapper-property:last-of-type {
  margin-right: 0;
}

.section-masthead__properties {
  margin-top: 70px;
  margin-bottom: -70px;
}

.section-masthead__background_bottom {
  margin-top: 100px;
}

.section-masthead__background_fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
}

.section-masthead__overlay {
  z-index: 0;
  opacity: .6;
}

.section-masthead__properties_bottom {
  position: absolute;
  bottom: 70px;
  left: 120px;
}

.section-masthead__content {
  width: 100%;
}

.section-masthead__inner {
  position: relative;
  z-index: 50;
}

@media screen and (max-width: 1400px) {
  .section-masthead__properties_bottom {
    left: 80px;
    bottom: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .section-masthead__wrapper-property {
    margin-right: 30px;
  }
  .section-masthead__properties_bottom {
    left: 50px;
  }
  .section-masthead__background_bottom {
    margin-top: 80px;
  }
}

@media screen and (max-width: 991px) {
  .section-masthead__wrapper-property {
    margin-right: 0;
  }
  .section-masthead__wrapper-property {
    margin-bottom: 30px;
  }
  .section-masthead__properties {
    margin-top: 30px;
    margin-bottom: -30px;
  }
  .section-masthead__properties_bottom {
    position: relative;
    left: auto;
    bottom: auto;
  }
  .section-masthead_fullheight-halfscreen {
    padding-top: 100px;
    flex-wrap: wrap;
  }
  .section-masthead_fullheight-halfscreen .section-fullheight__inner {
    min-height: unset;
    padding-top: 0;
    padding-bottom: 0;
  }
  .section-masthead_fullheight-halfscreen .section-masthead__background {
    margin-top: 80px;
  }
  .section-masthead_fullheight h1 {
    margin-top: 1.75em;
    margin-bottom: 0.5em;
  }
  .section-masthead__background_bottom {
    width: 100%;
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) {
  .section-masthead__header h1 {
    margin-top: 40px;
  }
  .section-masthead__header h2 {
    margin-top: 30px;
    font-size: 21px;
  }
  .section-masthead_fullheight-halfscreen {
    padding-top: 80px;
  }
  .section-masthead_fullheight-halfscreen .section-masthead__background {
    min-height: 0;
    height: 900px;
    max-height: 70vh;
    margin-top: 60px;
  }
  .section-masthead__background_bottom {
    margin-top: 60px;
  }
}

.section-nav-projects {
  background-color: #888888;
}

.section-nav-projects .section-nav-projects__inner_prev {
  padding-left: 120px;
  padding-right: 80px;
}

.section-nav-projects .section-nav-projects__inner_next {
  padding-left: 80px;
  padding-right: 120px;
}

.section-nav-projects__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 200px;
  padding-bottom: 200px;
  height: 100%;
  color: #ffffff;
  z-index: 50;
  background-image: none;
}

.section-nav-projects__inner h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.section-nav-projects__inner:nth-of-type(odd) {
  box-shadow: 1px 0px 0px 0px rgba(255, 255, 255, 0.1);
}

.section-nav-projects__inner:hover {
  opacity: 1;
  color: #ffffff;
}

.section-nav-projects__arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 1px solid rgba(214, 215, 217, 0.2);
}

.section-nav-projects__arrow > i {
  font-size: 32px;
}

.section-nav-projects__backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section-nav-projects__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  background-size: cover;
  background-position: center center;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}

.section-nav-projects__background.active, .section-nav-projects__background.selected {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.section-nav-projects__overlay {
  z-index: 0;
}

.section-nav-projects__label {
  position: absolute;
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .section-nav-projects__label {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.section-nav-projects__label_next {
  top: 60px;
  left: 80px;
}

.section-nav-projects__label_prev {
  bottom: 60px;
  right: 80px;
}

@media screen and (max-width: 1400px) {
  .section-nav-projects .section-nav-projects__inner_prev {
    padding-left: 80px;
    padding-right: 80px;
  }
  .section-nav-projects .section-nav-projects__inner_next {
    padding-left: 80px;
    padding-right: 80px;
  }
  .section-nav-projects__arrow {
    width: 60px;
    height: 60px;
  }
  .section-nav-projects__arrow > i {
    font-size: 24px;
  }
}

@media screen and (max-width: 1200px) {
  .section-nav-projects .section-nav-projects__inner_prev {
    padding-left: 50px;
    padding-right: 60px;
  }
  .section-nav-projects .section-nav-projects__inner_next {
    padding-left: 60px;
    padding-right: 50px;
  }
  .section-nav-projects__label_next {
    left: 60px;
  }
  .section-nav-projects__label_prev {
    right: 60px;
  }
  .section-nav-projects__inner {
    padding-top: 160px;
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 991px) {
  .section-nav-projects__arrow {
    width: 50px;
    height: 50px;
  }
  .section-nav-projects__arrow > i {
    font-size: 20px;
  }
  .section-nav-projects__inner {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) {
  .section-nav-projects .section-nav-projects__inner_prev {
    padding-right: 30px;
    padding-left: 30px;
  }
  .section-nav-projects .section-nav-projects__inner_next {
    padding-right: 30px;
    padding-left: 30px;
  }
  .section-nav-projects__label {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
    margin-bottom: 1em;
  }
  .section-nav-projects__inner {
    flex-direction: column;
    align-items: center;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .section-nav-projects__inner h3 {
    margin-bottom: 2em;
  }
  .section-nav-projects__arrow {
    order: 2;
  }
  .section-nav-projects__inner:nth-of-type(odd) {
    box-shadow: 0px 0px 1px 0px rgba(255, 255, 255, 0.1);
  }
}

.section-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-video__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: none;
  width: 100%;
  z-index: 100;
  cursor: none;
}

.section-video__link:hover {
  opacity: 1;
}

.section-video__link:hover .section-video__icon:after {
  transform: scale(1);
}

.section-video__link:focus {
  opacity: 1;
}

.section-video__icon {
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-top: 30px;
}

.section-video__icon:focus {
  outline: none;
}

.section-video__icon:after {
  content: '';
  display: block;
  border-radius: 100%;
  border: 1px solid #b0b0b0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.section-video__icon i {
  font-size: 32px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .section-video__icon:after {
    transform: scale(1);
  }
}

select {
  display: block;
  border: 1px solid rgba(100, 100, 100, 0.3);
  padding: 10px 35px 10px 15px;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../img/general/select.png");
  background-position: right 15px bottom 50%;
  background-repeat: no-repeat;
  background-size: 6px 4px;
}

.sidebar_no-margin-last-widget .widget:last-child {
  margin-bottom: 0;
}

.sidebar .widget {
  margin-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .sidebar {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar {
    margin-top: 40px;
  }
}

.slider {
  max-width: 100%;
}

.slider_vertical-centered .swiper-wrapper {
  align-items: center;
}

.slider__caption {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 30px;
}

.slider__counter {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  height: 16px;
}

@media only screen and (max-width: 991px) {
  .slider__counter {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.slider__counter_current {
  margin-right: 30px;
}

.slider__counter_total {
  margin-left: 30px;
  color: #888888;
}

.slider__progress {
  display: inline-flex;
  align-items: center;
  margin-top: 40px;
}

.slider__progressbar {
  position: relative;
  width: 180px;
  height: 1px;
  background: #b0b0b0;
}

.slider__progressbar-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #181818;
  transform-origin: left center;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider__dot {
  position: relative;
  width: 18px;
  height: 18px;
  margin: 0 16px;
  cursor: pointer;
  outline: none;
  display: inline-block;
}

.slider__dot:before {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #b0b0b0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider__dot:after {
  content: '';
  display: block;
  border-radius: 100%;
  border: 1px solid #b0b0b0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider__dot:hover:after {
  transform: scale(1);
}

.slider__dot_active:before {
  background-color: #181818;
}

.slider__dot_active:after {
  transform: scale(1);
  border-color: #181818;
}

.slider__backgrounds {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.slider__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: scale(1.05);
}

.slider__background.active, .slider__background.selected {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.slider__background.active ~ .slider__background-overlay, .slider__background.selected ~ .slider__background-overlay {
  opacity: .6;
}

.slider__background-overlay {
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 0 !important;
}

.slider__arrow {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider__arrow:focus {
  outline: none;
}

.slider__arrow:after {
  content: '';
  display: block;
  border-radius: 100%;
  border: 1px solid #b0b0b0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider__arrow:hover:after {
  transform: scale(1);
}

.slider__arrow i {
  font-size: 32px;
  line-height: 2;
}

.slider__arrow.swiper-button-disabled {
  opacity: 0.3;
}

.slider__arrow.swiper-button-disabled:hover:after {
  transform: scale(0);
}

.slider__arrow-inner {
  position: relative;
}

@media screen and (max-width: 1200px) {
  .slider__caption {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .slider__arrow {
    width: 60px;
    height: 60px;
  }
  .slider__arrow i {
    font-size: 24px;
  }
}

@media screen and (max-width: 991px) {
  .slider__progressbar {
    width: 140px;
  }
  .slider__dot {
    margin: 0 8px;
  }
  .slider__progress {
    margin-top: 25px;
  }
  .slider__counter_total {
    margin-left: 15px;
  }
  .slider__counter_current {
    margin-right: 15px;
  }
}

.slider-halfscreen_fullscreen {
  height: 100vh;
  flex-direction: column;
}

.slider-halfscreen_fullscreen .slider-halfscreen__images {
  width: 100%;
  height: 100%;
}

.slider-halfscreen_fullscreen .slider-halfscreen__content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
}

.slider-halfscreen_fullscreen .slider-halfscreen__content-inner {
  padding: 0 30px;
  text-align: center;
}

.slider-halfscreen_fullscreen .slider-halfscreen__content-inner h2 {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
}

.slider-halfscreen_fullscreen .slider-halfscreen__wrapper-link {
  margin-top: 40px;
  bottom: auto;
  left: auto;
  position: relative;
}

.slider-halfscreen_fullscreen .slider-halfscreen__link {
  display: inline-flex;
}

.slider-halfscreen_fullscreen .slider-halfscreen__header p {
  margin-left: auto;
  margin-right: auto;
}

.slider-halfscreen {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.slider-halfscreen__images {
  width: 50%;
  height: 100%;
  margin: 0;
}

.slider-halfscreen__content {
  width: 50%;
  height: 100%;
  margin: 0;
  max-height: 100%;
}

.slider-halfscreen__images-slide {
  overflow: hidden;
}

.slider-halfscreen__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.slider-halfscreen__content-slide {
  height: 100%;
}

.slider-halfscreen__content-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 120px 180px 120px 120px;
}

.slider-halfscreen__content-inner h2 {
  font-size: 54px;
  font-weight: 600;
  line-height: 1.59;
}

.slider-halfscreen__header {
  width: 100%;
}

.slider-halfscreen__header p {
  max-width: 500px;
  font-size: 16px;
}

.slider-halfscreen__wrapper-link {
  position: absolute;
  bottom: 80px;
  left: 120px;
}

.slider-halfscreen__link {
  display: flex;
  align-items: center;
  background-image: none;
}

.slider-halfscreen__link span {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .slider-halfscreen__link span {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.slider-halfscreen__link:hover {
  opacity: 1;
}

.slider-halfscreen__link-line {
  background-color: #ffffff;
  width: 60px;
  height: 1px;
  margin-right: 20px;
}

.slider-halfscreen__arrows {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 90px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 60;
}

.slider-halfscreen__images-slide-inner {
  width: 100%;
  height: 100%;
}

.slider-halfscreen__overlay {
  z-index: 1 !important;
}

@media screen and (max-width: 1400px) {
  .slider-halfscreen__content-inner {
    padding: 80px 120px 80px 80px;
  }
  .slider-halfscreen__content-inner h2 {
    font-size: 43px;
  }
  .slider-halfscreen__wrapper-link {
    bottom: 60px;
    left: 80px;
  }
  .slider-halfscreen__arrows {
    right: 50px;
  }
}

@media screen and (max-width: 1200px) {
  .slider-halfscreen__content-inner {
    padding: 50px 90px 50px 50px;
  }
  .slider-halfscreen__content-inner h2 {
    font-size: 28px;
  }
  .slider-halfscreen__wrapper-link {
    bottom: 50px;
    left: 50px;
  }
  .slider-halfscreen__arrows {
    right: 30px;
  }
}

@media screen and (max-width: 991px) {
  .slider-halfscreen {
    height: 100vh;
    flex-direction: column;
  }
  .slider-halfscreen__images {
    width: 100%;
    height: 100%;
  }
  .slider-halfscreen__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
  }
  .slider-halfscreen__content-inner {
    padding: 0 30px;
    text-align: center;
  }
  .slider-halfscreen__content-inner h2 {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }
  .slider-halfscreen__wrapper-link {
    margin-top: 15px;
    bottom: auto;
    left: auto;
    position: relative;
  }
  .slider-halfscreen__link {
    justify-content: center;
  }
  .slider-halfscreen__arrows {
    left: 0;
    right: 0;
    top: auto;
    bottom: 15px;
    transform: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 80px;
    width: 100%;
    padding: 0 15px;
  }
  .slider-halfscreen__header p {
    margin-left: auto;
    margin-right: auto;
  }
}

.slider-headings {
  text-align: center;
  height: 100%;
}

.slider-headings .slider__dot:before {
  background-color: #181818;
}

.slider-headings .slider__dot:after {
  border-color: #181818;
}

.slider-headings__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: 0 auto;
  text-align: center;
  z-index: 60;
}

.slider-headings__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-headings__slide h2 {
  font-size: 68px;
}

.slider-headings__link {
  display: block;
  padding-right: 15px;
  padding-left: 15px;
  background-image: none;
}

.slider-headings__link:hover {
  opacity: 1;
  color: #ffffff;
}

.slider-headings__progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  z-index: -1;
  opacity: .05;
  height: 300px;
  margin-top: 0;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider-headings__counter {
  font-size: 300px;
  line-height: 300px;
  height: 300px;
  overflow: hidden;
}

.slider-headings__arrow {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  z-index: 60;
}

.slider-headings__arrow_prev {
  left: 80px;
}

.slider-headings__arrow_next {
  right: 80px;
}

@media screen and (max-width: 1400px) {
  .slider-headings__arrow_prev {
    left: 45px;
  }
  .slider-headings__arrow_next {
    right: 45px;
  }
  .slider-headings__slide h2 {
    font-size: 54px;
  }
  .slider-headings__counter {
    font-size: 250px;
    line-height: 250px;
    height: 250px;
  }
}

@media screen and (max-width: 1200px) {
  .slider-headings__arrow_prev {
    left: 25px;
  }
  .slider-headings__arrow_next {
    right: 25px;
  }
}

@media screen and (max-width: 991px) {
  .slider-headings__slide h2 {
    font-size: 28px;
    line-height: 1.3;
  }
  .slider-headings__arrow:after {
    transform: scale(1) !important;
  }
  .slider-headings__arrow_prev {
    left: 30px;
  }
  .slider-headings__arrow_next {
    right: 30px;
  }
  .slider-headings__counter {
    font-size: 150px;
    line-height: 150px;
    height: 150px;
  }
}

@media screen and (max-width: 767px) {
  .slider-headings__arrow {
    display: none;
  }
}

.slider-testimonials__slide {
  display: flex;
  align-items: center;
  padding: 50px 0;
}

.slider-testimonials__slide blockquote {
  font-size: 20px;
  line-height: 1.75;
  font-style: italic;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

.slider-testimonials__footer {
  border-top: 1px solid rgba(176, 176, 176, 0.3);
  text-align: center;
  padding-top: 30px;
}

.slider-testimonials__wrapper-avatar {
  border-radius: 100%;
  overflow: hidden;
  max-width: 164px;
  max-height: 164px;
  flex: 1 0 auto;
  margin-right: 50px;
}

.slider-header-testimonials {
  border-bottom: 1px solid rgba(176, 176, 176, 0.3);
  padding-bottom: 25px;
}

.slider-header-testimonials:before, .slider-header-testimonials:after {
  content: '';
  display: block;
  height: 100%;
  width: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 50;
}

.slider-header-testimonials:before {
  left: 0;
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, white 100%);
}

.slider-header-testimonials:after {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
}

.slider-header-testimonials__slide {
  text-align: center;
  cursor: pointer;
}

.slider-header-testimonials__slide span {
  display: block;
  opacity: .3;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.slider-header-testimonials__slide.swiper-slide-active span {
  opacity: 1;
}

@media only screen and (max-width: 991px) {
  .slider-testimonials__wrapper-avatar {
    max-width: 80px;
    max-height: 80px;
    margin-right: 0;
    margin-bottom: 1em;
  }
  .slider-testimonials__slide {
    flex-direction: column;
    text-align: center;
    padding: 30px 0;
  }
  .slider-testimonials__slide blockquote {
    font-size: 16px;
  }
  .slider-header-testimonials {
    padding-bottom: 15px;
  }
  .slider-header-testimonials__slide span {
    font-size: 16px;
  }
}

.slider-text {
  width: 100%;
  height: 100%;
}

.slider-text__upper {
  height: 50%;
}

.slider-text__upper .slider-text__slide {
  align-items: flex-end;
}

.slider-text__lower {
  height: 50%;
}

.slider-text__lower .slider-text__slide {
  align-items: flex-start;
}

.slider-text__slide {
  flex: 1 1 auto;
  padding: 15px 0;
  display: flex;
  justify-content: center;
}

.slider-text__slide h2 {
  font-size: 54px;
  font-weight: 600;
  line-height: 1.59;
  white-space: nowrap;
  margin-bottom: 0;
}

.slider-text__header {
  position: relative;
}

.slider-text__line {
  width: 120px;
  height: 2px;
  background-color: #181818;
  margin-left: 30px;
  margin-right: 30px;
}

.slider-text__counter {
  position: absolute;
  top: -15px;
  right: 100%;
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #888888;
  white-space: nowrap;
}

@media only screen and (max-width: 991px) {
  .slider-text__counter {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.slider-text__link {
  display: flex;
  align-items: center;
  background-image: none;
}

.slider-text__link:hover {
  opacity: 1;
  color: #ffffff;
}

.slider-text__helper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: 0 auto;
  text-align: center;
}

.slider-text__helper-label-normal {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 50px;
  padding-bottom: 2px;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .slider-text__helper-label-normal {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.slider-text__helper-label-view {
  position: absolute;
  left: 0;
  right: 0;
  font-family: "Oswald", sans-serif;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 2px;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .slider-text__helper-label-view {
    font-size: 11px;
    letter-spacing: 0.8px;
  }
}

.slider-text__helper__inner {
  position: relative;
  display: inline-flex;
  align-items: center;
}

@media screen and (max-width: 1400px) {
  .slider-text__slide h2 {
    font-size: 43px;
  }
}

@media screen and (max-width: 991px) {
  .slider-text__slide h2 {
    font-size: 28px;
  }
}

@media screen and (max-width: 767px) {
  .slider-text__slide {
    text-align: center;
    flex: 1 0 100%;
  }
  .slider-text__slide h2 {
    white-space: unset;
    line-height: 1.3;
  }
  .slider-text__line {
    display: none;
  }
  .slider-text__counter {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 15px;
    right: auto;
  }
}

.social {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: -8px;
}

.social__item {
  display: inline-block;
  margin: 8px;
}

.social__item a {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 48px;
  height: 48px;
  font-size: 16px;
  border-radius: 100%;
  background-image: none;
}

.social__item a:after {
  content: '';
  display: block;
  border-radius: 100%;
  border: 1px solid #b0b0b0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scale(0);
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.social__item a:hover {
  opacity: 1;
}

.social__item a:hover:after {
  transform: scale(1);
}

@media screen and (max-width: 991px) {
  .social__item a {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}

.spinner {
  position: fixed;
  left: 120px;
  bottom: calc(var(--fix-bar-vh, 30px) + 15px);
  width: 30px;
  height: 30px;
  z-index: 10000;
  animation: rotator 1.2s ease-in-out infinite;
  opacity: 0;
  visibility: hidden;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.spinner__path {
  stroke: #b0b0b0;
  stroke-dasharray: 202;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.2s ease-in-out infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 202;
    transform: rotate(0deg);
  }
  50% {
    stroke-dashoffset: 50.5;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 202;
    transform: rotate(450deg);
  }
}

@media screen and (max-width: 1400px) {
  .spinner {
    left: 80px;
  }
}

@media screen and (max-width: 1200px) {
  .spinner {
    left: 30px;
  }
}

.split-text, .split-chars {
  opacity: 0;
  visibility: hidden;
}

.split-text__line {
  overflow: hidden;
}

.split-chars__line {
  display: inline;
  overflow: hidden;
}

.tagcloud ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tagcloud ul li {
  display: inline-block !important;
  margin-bottom: 0 !important;
}

.tagcloud a {
  display: inline-block;
  font-size: 12px !important;
  line-height: 1;
  color: #181818;
  background-color: #f2f2f2;
  padding: 11px 15px;
  margin-bottom: 6px;
  margin-right: 4px;
  border-radius: 100px;
  background-image: none;
}

.tagcloud a:hover {
  background-color: #d6d7d9;
  opacity: 1;
}

h1, h2, h3, h4, h5 {
  font-family: "Poppins", sans-serif;
}

h6 {
  font-family: "Oswald", sans-serif;
}

h1 {
  font-size: 54px;
  font-weight: 600;
  line-height: 1.59;
}

h2 {
  font-size: 33px;
  font-weight: 600;
  line-height: 1.5;
}

h3 {
  font-size: 27px;
  font-weight: 300;
  line-height: 1.67;
  margin-bottom: 1em;
}

h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.67;
}

h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.67;
}

h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
}

p {
  font-weight: 300;
  margin-top: 1em;
  margin-bottom: 1em;
}

strong {
  font-weight: 600;
}

small {
  font-size: 14px;
}

.heading-light {
  font-weight: 300;
}

a {
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color: inherit;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0) 0px, #888888 0px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 100px);
}

a:hover, a:focus {
  text-decoration: none;
  color: inherit;
  opacity: .3;
}

a:focus {
  color: inherit;
  outline: none;
}

@media screen and (max-width: 1400px) {
  h1 {
    font-size: 43px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 19px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 19px;
  }
  h4 {
    font-size: 17px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 16px;
  }
  small {
    display: inline-block;
    font-size: 12px;
  }
}

.text-left {
  text-align: left !important;
}

.text-left .section__headline {
  margin-right: auto;
}

.text-center {
  text-align: center !important;
}

.text-center .section__headline {
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right !important;
}

.text-right .section__headline {
  margin-left: auto;
}

.row-80 {
  margin-left: -40px;
  margin-right: -40px;
}

.overflow {
  position: relative;
  overflow: hidden;
}

.overflow__curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
}

.overflow__content {
  width: 100%;
  height: 100%;
}

.position-relative {
  position: relative;
}

.color-white {
  color: #ffffff !important;
}

.color-black {
  color: #0f0f0f !important;
}

.color-dark {
  color: #181818 !important;
}

.blurred {
  filter: blur(7px);
}

.of-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.of-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

.split-line {
  overflow: hidden;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

.grayscale {
  filter: grayscale(70%);
}

.h-100 {
  height: 100%;
}

.lockhover .menu-overlay > li > a:not(.selected) {
  opacity: .2;
  pointer-events: none;
}

.lockhover .menu-overlay .sub-menu > li > a:not(.selected) {
  opacity: .2;
  pointer-events: none;
}

#js-webgl {
  display: none;
  width: 1px;
  height: 1px;
}

.widget p:last-of-type {
  margin-bottom: 0;
}

.widget select {
  width: 100%;
  max-width: 100%;
}

.widget ul:not(.social) {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.widget ul:not(.social) li {
  display: block;
  margin-bottom: 20px;
}

.widget ul:not(.social) li a:hover {
  color: var(--color-accent-primary);
}

.widget ul:not(.social) li:last-child {
  margin-bottom: 0;
}

.widget ul:not(.social) ul {
  width: 100%;
  padding-left: 1em;
  margin-top: 1em;
}

.widget ul li a {
  background-image: none;
}

.widgettitle {
  display: block;
  font-family: "Oswald", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 1.5em;
}

.widgettitle:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #181818;
  margin-bottom: 0.5em;
}

.widget_archive ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.widget_archive ul li span {
  color: #181818;
}

#wp-calendar {
  width: 100%;
  text-align: center;
}

#wp-calendar caption {
  caption-side: top;
  width: 100%;
  text-align: center;
  padding-top: 0;
  padding-bottom: 10px;
}

#wp-calendar th {
  font-weight: 600;
  padding: 5px;
  text-align: center;
}

#wp-calendar td {
  padding: 5px;
  color: #181818;
}

#wp-calendar td#next {
  text-align: right;
}

#wp-calendar td#prev {
  text-align: left;
}

#wp-calendar tr {
  border-bottom: none;
}

.widget_categories ul li {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.widget_categories ul li span {
  color: #888888;
  font-size: 13px;
  line-height: 1;
}

.widget_ruben_logo {
  max-width: 360px;
}

.widget_ruben_logo .logo {
  margin-bottom: 2em;
}

@media screen and (max-width: 991px) {
  .widget_ruben_logo {
    max-width: 100%;
  }
  .widget_ruben_logo .logo {
    margin-bottom: 1em;
  }
}

.widget_nav_menu ul.menu > li {
  margin-bottom: 1em !important;
  white-space: normal;
  word-break: break-word;
}

.widget_nav_menu ul.menu > li a {
  display: inline-block;
  padding: 0;
  font-size: 16px;
}

.widget_nav_menu ul.menu > li a:after, .widget_nav_menu ul.menu > li a:before {
  display: none;
}

.widget_nav_menu ul.menu > li.menu-item-has-children {
  margin-bottom: 1.5em !important;
}

.widget_nav_menu ul.menu > li.menu-item-has-children a:after {
  display: none;
}

.widget_nav_menu ul.sub-menu {
  background-color: transparent;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  opacity: 1;
  visibility: visible;
  box-shadow: none;
  border-bottom: none;
  transform: none;
  margin-top: 1em;
}

.widget_nav_menu ul.sub-menu > li a {
  padding: 3px 0;
}

.widget_rss .rsswidget {
  font-weight: 600;
}

.widget_rss .rss-date {
  display: block;
  width: 100%;
  font-size: 13px;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.widget_rss .rssSummary {
  margin-top: 1em;
  margin-bottom: 1em;
}

.widget_rss ul > li {
  border-bottom: 1px solid rgba(176, 176, 176, 0.3);
  padding-bottom: 1em;
}

.widget_rss ul > li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.widget_recent_entries .post-date {
  display: block;
  width: 100%;
  font-size: 13px;
  margin-top: 0.25em;
  color: #888888;
}

.widget_recent_entries ul li {
  padding: 20px 0;
  margin-bottom: 0 !important;
  border-bottom: 1px solid rgba(176, 176, 176, 0.3);
}

.widget_recent_entries ul li:first-child {
  padding-top: 0;
}

.widget_recent_entries ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
