.section-content__inner > *:last-child
	margin-bottom: 0
.section-content__main-content
	margin-top: 100px
	margin-bottom: -50px
.section-content__wrapper-item
	margin-bottom: 50px
.section-content__main-content_no-header
	margin-top: 0 !important
.section-content__inner_header
	margin-bottom: 1em

@media screen and (max-width: $md)
	.section-content__main-content
		margin-top: 60px
		margin-bottom: -30px
	.section-content__wrapper-item
		margin-bottom: 30px
