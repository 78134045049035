.figure-pricing
	position: relative
	display: flex
	flex-direction: column
	padding: 50px
	position: relative
	height: 100%
	z-index: 50
	background-color: $very-light-pink
.figure-pricing_featured
	background-color: $dark
	color: $white
	.figure-pricing__header, .button
		border-color: $white
.figure-pricing__header
	display: flex
	align-items: flex-end
	justify-content: space-between
	border-bottom: 2px solid $dark
	padding-bottom: 16px
	h4
		margin-top: 0
		margin-bottom: 0
.figure-pricing__amount
	font-size: 68px
	line-height: 1
	font-weight: 200
	position: relative
	display: inline-block
.figure-pricing__cost-sign
	display: inline-block
	font-size: 16px
	font-weight: 600
	vertical-align: top
.figure-pricing__cost-sign_before
	margin-right: 5px
.figure-pricing__cost-sign_after
	margin-left: 5px
.figure-pricing__features
	+reset-ul
	margin-top: 8px
	margin-bottom: 30px
.figure-pricing__feature
	display: block
	margin-top: 16px
	margin-bottom: 16px
.figure-pricing__wrapper-button
	margin-top: auto

@media screen and (max-width: $xl)
	.figure-pricing
		padding: 30px
	.figure-pricing__amount
		font-size: 53px
	.figure-pricing__cost
		margin-bottom: -5px
