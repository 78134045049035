.social
	+reset-ul
	margin: -8px
.social__item
	display: inline-block
	margin: 8px
	a
		position: relative
		display: inline-flex
		justify-content: center
		align-items: center
		background-color: transparent
		width: 48px
		height: 48px
		font-size: 16px
		border-radius: 100%
		background-image: none
		&:after
			content: ''
			display: block
			border-radius: 100%
			border: 1px solid $brown-grey
			position: absolute
			top: 0
			left: 0
			right: 0
			bottom: 0
			transform: scale(0)
			+trans1
		&:hover
			opacity: 1
			&:after
				transform: scale(1)

@media screen and (max-width: $md)
	.social__item a
		width: 40px
		height: 40px
		font-size: 14px
