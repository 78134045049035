.section-nav-projects
	background-color: $brown-grey-2
	.section-nav-projects__inner_prev
		padding-left: 120px
		padding-right: 80px
	.section-nav-projects__inner_next
		padding-left: 80px
		padding-right: 120px
.section-nav-projects__inner
	display: flex
	align-items: center
	justify-content: space-between
	position: relative
	padding-top: 200px
	padding-bottom: 200px
	height: 100%
	color: $white
	z-index: 50
	background-image: none
	h3
		margin-top: 0
		margin-bottom: 0
	&:nth-of-type(odd)
		box-shadow: 1px 0px 0px 0px rgba(255, 255, 255, 0.1)
	&:hover
		opacity: 1
		color: $white
.section-nav-projects__arrow
	display: inline-flex
	align-items: center
	justify-content: center
	width: 80px
	height: 80px
	border-radius: 100%
	border: 1px solid $borders-light
	> i
		font-size: 32px
.section-nav-projects__backgrounds
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	overflow: hidden
.section-nav-projects__background	
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	opacity: 0
	visibility: hidden
	background-size: cover
	background-position: center center
	transition: all 0.3s ease-in-out
	transform: scale(1.05)
	&.active, &.selected
		opacity: 1
		visibility: visible
		transform: scale(1)
.section-nav-projects__overlay
	z-index: 0
.section-nav-projects__label
	position: absolute
	+small-caps
.section-nav-projects__label_next
	top: 60px
	left: 80px
.section-nav-projects__label_prev
	bottom: 60px
	right: 80px

@media screen and (max-width: $xxl)
	.section-nav-projects
		.section-nav-projects__inner_prev
			padding-left: 80px
			padding-right: 80px
		.section-nav-projects__inner_next
			padding-left: 80px
			padding-right: 80px
	.section-nav-projects__arrow
		width: 60px
		height: 60px
	.section-nav-projects__arrow > i
		font-size: 24px

@media screen and (max-width: $xl)
	.section-nav-projects
		.section-nav-projects__inner_prev
			padding-left: 50px
			padding-right: 60px
		.section-nav-projects__inner_next
			padding-left: 60px
			padding-right: 50px
	.section-nav-projects__label_next
		left: 60px
	.section-nav-projects__label_prev
		right: 60px
	.section-nav-projects__inner
		padding-top: 160px
		padding-bottom: 160px

@media screen and (max-width: $md)
	.section-nav-projects__arrow
		width: 50px
		height: 50px
	.section-nav-projects__arrow > i
		font-size: 20px
	.section-nav-projects__inner
		padding-top: 120px
		padding-bottom: 120px

@media screen and (max-width: $sm)
	.section-nav-projects
		.section-nav-projects__inner_prev
			padding-right: 30px
			padding-left: 30px
		.section-nav-projects__inner_next
			padding-right: 30px
			padding-left: 30px
	.section-nav-projects__label
		position: relative
		left: auto
		right: auto
		bottom: auto
		top: auto
		margin-bottom: 1em
	.section-nav-projects__inner
		flex-direction: column
		align-items: center
		padding-top: 80px
		padding-bottom: 80px
		h3
			margin-bottom: 2em
	.section-nav-projects__arrow
		order: 2
	.section-nav-projects__inner
		&:nth-of-type(odd)
			box-shadow: 0px 0px 1px 0px rgba(255, 255, 255, 0.1)
