.figure-portfolio
	position: relative
	display: block
	background-image: none
	&:hover, &:focus
		opacity: 1
.figure-portfolio__content
	text-align: right
	h3
		margin-top: 30px
		margin-bottom: 0
		color: $black
		font-weight: 600
.figure-portfolio__counter
	font-family: $font-secondary
	font-size: 16px
	font-weight: 500
	line-height: 1.5
	letter-spacing: 1px
	text-transform: uppercase
	display: inline-block
	position: absolute
	top: -30px
	left: -30px
	color: $brown-grey-2
	transform: rotate(-90deg)
	transform-origin: left center

@media screen and (max-width: $xxl)
	.figure-portfolio__counter
		font-size: 12px
		top: -20px
		left: -20px
	.figure-portfolio__content
		h3
			margin-top: 20px
@media screen and (max-width: $md)
	.figure-portfolio__counter
		top: -13px
		left: -13px
	.figure-portfolio__content
		text-align: center
		h3
			margin-top: 15px
