.grid__item_no-fancy
	&:nth-of-type(3)
		margin-top: 0 !important
//////////////////////////////////////////
.grid_50
	margin-left: -50px
	margin-right: -50px
.grid__item_50
	padding-left: 50px
	padding-right: 50px
	margin-bottom: 100px
	&:nth-of-type(3)
		margin-top: 200px
	&:last-of-type
		margin-bottom: 0
//////////////////////////////////////////
.grid_100
	margin-left: -100px
	margin-right: -100px
.grid__item_100
	padding-left: 100px
	padding-right: 100px
	margin-bottom: 200px
	&:nth-of-type(3)
		margin-top: 200px
	&:last-of-type
		margin-bottom: 0
//////////////////////////////////////////
.grid__item_120
	padding-left: 120px
	padding-right: 120px
	margin-bottom: 240px
	&:nth-of-type(3)
		margin-top: 240px
	&:last-of-type
		margin-bottom: 0
//////////////////////////////////////////

.grid_team
	margin-left: -60px
	margin-right: -60px
.grid__item_team
	padding-left: 60px
	padding-right: 60px
	margin-bottom: 100px
	&:nth-of-type(3)
		margin-top: 100px
	&:last-of-type
		margin-bottom: 0

@media screen and (max-width: $xxl)
	.grid__item_120
		padding-left: 80px
		padding-right: 80px
		margin-bottom: 160px
		&:nth-of-type(3)
			margin-top: 160px
	.grid_100
		margin-left: -50px
		margin-right: -50px


@media screen and (max-width: $xl)
	//////////////////////////////////////////
	.grid_120
		margin-left: -50px
		margin-right: -50px
	.grid__item_120
		padding-left: 50px
		padding-right: 50px
		margin-bottom: 100px
		&:nth-of-type(3)
			margin-top: 100px
	

@media screen and (max-width: $md)
	.grid_120, .grid_100, .grid_50
		margin-left: -15px
		margin-right: -15px
	.grid__item_120, .grid__item_100, .grid__item_50
		padding-left: 15px
		padding-right: 15px
		margin-bottom: 40px
		&:nth-of-type(3)
			margin-top: 0
