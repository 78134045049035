.footer
	position: relative
	background-color: $black0
	color: $brown-grey-2
	font-size: 16px
	a
		color: $white
.footer__area-primary
	padding-top: 160px
	padding-bottom: 160px
	.footer__col
		&:nth-child(1)
			text-align: left
		&:nth-child(2)
			text-align: center
		&:nth-child(3)
			text-align: right
.footer__area-secondary
	padding-top: 50px
	padding-bottom: 50px
	border-top: 1px solid $borders-light
	.footer__col
		&:nth-child(1)
			text-align: center

@media screen and (max-width: $xl)
	.footer__area-primary
		padding-top: 100px
		padding-bottom: 100px
	.footer__area-secondary
		padding-top: 30px
		padding-bottom: 30px

@media screen and (max-width: $md)
	.footer__area-primary
		padding-top: 60px
		padding-bottom: 50px
		.footer__col
			margin-bottom: 30px
			&:nth-of-type(1)
				text-align: center
			&:nth-of-type(2)
				text-align: center
			&:nth-of-type(3)
				text-align: center
			&:last-of-type
				margin-bottom: 0
	.footer__area-secondary
		padding-top: 20px
		padding-bottom: 20px
