.section-figures
	.section-figures__item
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: center
		padding: 200px 120px 
		h5
			width: 100%
			margin-top: 1em
			margin-bottom: 0
	.section-figures__item_no-paddings
		padding: 0

@media screen and (max-width: $xxl)
	.section-figures
		.section-figures__item:not(.section-figures__item_no-paddings)
			padding: 120px 80px

@media screen and (max-width: $xl)
	.section-figures
		.section-figures__item:not(.section-figures__item_no-paddings)
			padding: 80px 50px
