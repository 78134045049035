.button
	position: relative
	display: inline-block
	padding: 22px 50px
	border-radius: 100px
	border-style: solid
	border-width: 2px
	border-color: transparent
	+small-caps
	text-align: center
	+trans1
	cursor: pointer
	background-image: none
	&:focus
		outline: none
	&:hover, &:focus
		opacity: 1
		transform: translateY(-3px)
.button_bordered
	&.button_dark
		border-color: $dark
		color: $dark
		&:focus
			color: $dark
		&:hover
			color: $dark
	&.button_light
		border-color: $light-grey
		color: $light-grey
		&:focus
			color: $light-grey
		&:hover
			color: $dark
	&.button_black
		border-color: $black
		color: $black
		&:focus
			color: $black
		&:hover
			color: $white
	&.button_white
		border-color: $white
		color: $white
		&:focus
			color: $white
		&:hover
			color: $dark
			background-color: $white
.button_solid
	border-color: transparent
	&.button_dark
		background-color: $dark
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: $dark
	&.button_light
		background-color: $light-grey
		color: $dark
		&:focus
			color: $dark
		&:hover
			border-color: $light-grey
	&.button_black
		background-color: $black
		color: $white
		&:focus
			color: $white
		&:hover
			border-color: $dark
	&.button_white
		background-color: $white
		color: $dark
		&:focus
			color: $dark
		&:hover
			border-color: $dark
			background-color: $dark
			color: $white
.button_fullwidth
	width: 100%
.button_small
	padding: 16px 32px
