.slider-halfscreen
	display: flex
	justify-content: flex-start
	align-items: center
	width: 100%
	height: 100vh
	overflow: hidden
.slider-halfscreen__images
	width: 50%
	height: 100%
	margin: 0
.slider-halfscreen__content
	width: 50%
	height: 100%
	margin: 0
	max-height: 100%
.slider-halfscreen__images-slide
	overflow: hidden
.slider-halfscreen__bg
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-position: center center
	background-size: cover
.slider-halfscreen__content-slide
	height: 100%
.slider-halfscreen__content-inner
	position: relative
	display: flex
	flex-direction: column
	justify-content: center
	width: 100%
	height: 100%
	padding: 120px 180px 120px 120px
	h2
		font-size: 54px
		font-weight: 600
		line-height: 1.59
.slider-halfscreen__header
	width: 100%
	p
		max-width: 500px
		font-size: 16px
.slider-halfscreen__wrapper-link
	position: absolute
	bottom: 80px
	left: 120px
.slider-halfscreen__link
	display: flex
	align-items: center
	background-image: none
	span
		+small-caps
	&:hover
		opacity: 1
.slider-halfscreen__link-line
	background-color: $white
	width: 60px
	height: 1px
	margin-right: 20px
.slider-halfscreen__arrows
	display: flex
	flex-direction: column
	position: absolute
	right: 90px
	top: 50%
	transform: translateY(-50%)
	z-index: 60
.slider-halfscreen__images-slide-inner
	width: 100%
	height: 100%
.slider-halfscreen__overlay
	z-index: 1 !important

@media screen and (max-width: $xxl)
	.slider-halfscreen__content-inner
		padding: 80px 120px 80px 80px
		h2
			font-size: 43px
	.slider-halfscreen__wrapper-link
		bottom: 60px
		left: 80px
	.slider-halfscreen__arrows
		right: 50px

@media screen and (max-width: $xl)
	.slider-halfscreen__content-inner
		padding: 50px 90px 50px 50px
		h2
			font-size: 28px
	.slider-halfscreen__wrapper-link
		bottom: 50px
		left: 50px
	.slider-halfscreen__arrows
		right: 30px

@media screen and (max-width: $md)
	.slider-halfscreen
		height: 100vh
		flex-direction: column
	.slider-halfscreen__images
		width: 100%
		height: 100%
	.slider-halfscreen__content
		position: absolute
		top: 50%
		transform: translateY(-50%)
		width: 100%
		height: auto
	.slider-halfscreen__content-inner
		padding: 0 30px
		text-align: center
		h2
			margin-top: 0
			margin-bottom: 0
			width: 100%
	.slider-halfscreen__wrapper-link
		margin-top: 15px
		bottom: auto
		left: auto
		position: relative
	.slider-halfscreen__link
		justify-content: center
	.slider-halfscreen__arrows
		left: 0
		right: 0
		top: auto
		bottom: 15px
		transform: none
		flex-direction: row
		justify-content: space-between
		align-items: center
		flex: 0 1 80px
		width: 100%
		padding: 0 15px
	.slider-halfscreen__header p
		margin-left: auto
		margin-right: auto
