.slider
	max-width: 100%
.slider_vertical-centered
	.swiper-wrapper
		align-items: center
.slider__caption
	font-weight: 600
	font-size: 20px
	margin-bottom: 30px

.slider__counter
	+small-caps
	height: 16px
.slider__counter_current
	margin-right: 30px
.slider__counter_total
	margin-left: 30px
	color: $brown-grey-2

.slider__progress
	display: inline-flex
	align-items: center
	margin-top: 40px
.slider__progressbar
	position: relative
	width: 180px
	height: 1px
	background: $brown-grey
.slider__progressbar-fill
	position: absolute
	top: 0
	left: 0
	bottom: 0
	width: 100%
	background-color: $dark
	transform-origin: left center
	+trans1

.slider__dot
	position: relative
	width: 18px
	height: 18px
	margin: 0 16px
	cursor: pointer
	outline: none
	display: inline-block
	&:before
		content: ''
		display: block
		width: 4px
		height: 4px
		border-radius: 100%
		background-color: $brown-grey
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		margin: auto
		+trans1
	&:after
		content: ''
		display: block
		border-radius: 100%
		border: 1px solid $brown-grey
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		transform: scale(0)
		+trans1
	&:hover
		&:after
			transform: scale(1)
.slider__dot_active
	&:before
		background-color: $dark
	&:after
		transform: scale(1)
		border-color: $dark
.slider__backgrounds
	position: fixed
	top: 0
	left: 0
	z-index: -1
	width: 100vw
	height: 100vh
	overflow: hidden
.slider__background
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-size: cover
	background-position: center center
	opacity: 0
	visibility: hidden
	transition: all 0.3s ease-in-out
	transform: scale(1.05)
	&.active, &.selected
		opacity: 1
		visibility: visible
		transform: scale(1)
		~ .slider__background-overlay
			opacity: .6
.slider__background-overlay
	opacity: 0
	+trans1
	z-index: 0 !important

.slider__arrow
	position: relative
	display: inline-flex
	align-items: center
	justify-content: center
	width: 80px
	height: 80px
	border-radius: 100%
	cursor: pointer
	+trans1
	&:focus
		outline: none
	&:after
		content: ''
		display: block
		border-radius: 100%
		border: 1px solid $brown-grey
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		transform: scale(0)
		+trans1
	&:hover
		&:after
			transform: scale(1)
	i
		font-size: 32px
		line-height: 2
	&.swiper-button-disabled
		opacity: 0.3
		&:hover
			&:after
				transform: scale(0)
.slider__arrow-inner
	position: relative

@media screen and (max-width: $xl)
	.slider__caption
		font-size: 16px
		margin-bottom: 20px
	.slider__arrow
		width: 60px
		height: 60px
		i
			font-size: 24px

@media screen and (max-width: $md)
	.slider__progressbar
		width: 140px
	.slider__dot
		margin: 0 8px
	.slider__progress
		margin-top: 25px
	.slider__counter_total
		margin-left: 15px
	.slider__counter_current
		margin-right: 15px
