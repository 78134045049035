.project-backgrounds
	position: fixed
	top: 0
	left: 0
	z-index: -1
	width: 100vw
	height: 100vh
	overflow: hidden
	background-color: $dark-2
	opacity: 0
	visibility: hidden
.project-backgrounds__background
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-size: cover
	background-position: center center
	opacity: 0
	visibility: hidden
	transition: all 0.3s ease-in-out
	transform: scale(1.05)
	&.active, &.selected
		opacity: 1
		visibility: visible
		transform: scale(1)
.project-backgrounds__overlay
	+trans1
	z-index: 0 !important
