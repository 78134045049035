.figure-member
	position: relative
.figure-member_has-social
	&:hover
		.figure-member__wrapper-img img
			transform: scale(1.1)
		.figure-member__overlay
			transform: scaleX(1)
		.figure-member__social
			li
				transform: translateX(0px)
				opacity: 1
				visibility: visible
				@for $i from 1 through 10
					&:nth-child(#{$i})
						transition-delay: 50ms + $i * 50ms
.figure-member__content
	text-align: right
	margin-top: 1em
.figure-member__position
	+small-caps
	position: absolute
	top: -7px
	right: calc(100% + 25px)
	color: $brown-grey-2
	transform: rotate(-90deg)
	transform-origin: right center
	text-align: right
	white-space: nowrap
.figure-member__wrapper-img
	position: relative
	overflow: hidden
	img
		+trans1
		transform-origin: center center
.figure-member__overlay
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: $dark
	+trans1
	transform: scaleX(0)
	transform-origin: right center
.figure-member__social
	position: absolute
	top: 30px
	left: 30px
	z-index: 50
	color: $white
	li
		display: block
		transform: translateX(30px)
		transition: all 0.6s ease
		opacity: 0
		visibility: hidden
		margin-right: 0
		margin-bottom: 15px

@media screen and (max-width: $xxl)
	.figure-member__position
		font-size: 12px
@media screen and (max-width: $md)
	.figure-member
		text-align: center
	.figure-member__content
		text-align: center
		margin-top: 20px
	.figure-member__position
		position: relative
		top: auto
		left: auto
		right: auto
		bottom: auto
		transform: none
		text-align: center
		margin-bottom: 10px
