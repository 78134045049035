.post__content
	h2, h3, h4
		margin-top: 0.75em
		margin-bottom: 0.75em
	h5, h6
		margin-top: 0.5em
		margin-bottom: 0.5em
	> *:first-child
		margin-top: 0
.post__media
	margin-bottom: 2em
.post__tags
	border-top: 2px solid $dark
	margin-top: 50px
	padding-top: 20px
.post__comments
	margin-top: 100px
