$em-base: 16px
$font-primary: 'Poppins', sans-serif
$font-secondary: 'Oswald', sans-serif

// Color Palette
$black: #333333
$dark: #181818
$dark-1: #1f1f1f
$dark-2: #232323
$black0: #0f0f0f
$black1: #121212
$white: #ffffff
$light-grey: #f9f9f9
$very-light-pink: #f2f2f2
$light-blue-grey: #d6d7d9
$light-blue-grey-2: #c5c6c9
$brown-grey: #b0b0b0
$brown-grey-2: #888888
$borders-light: rgba(214, 215, 217, .2)
$borders-dark: rgba(176, 176, 176, .3)
$borders-darker: rgba(100, 100, 100, .3)

// Responsive Breakpoints
$xxl: 1400px
$xl: 1200px
$lg: 1024px
$md: 991px
$sm: 767px
$xs: 576px
