.bg-white
	background-color: $white
.bg-light
	background-color: $very-light-pink
	.slider-header-testimonials
		&:before
			background: linear-gradient(-90deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 100%)
		&:after
			background: linear-gradient(90deg, rgba(242, 242, 242, 0) 0%, rgba(242, 242, 242, 1) 100%)
.bg-light-grey
	background-color: $light-grey
.bg-blue-grey
	background-color: $light-blue-grey
.bg-blue-grey-dark
	background-color: $light-blue-grey-2
	.aside-counters__wrapper-item
		&:after
			background-color: $brown-grey
.bg-dark
	background-color: $dark
.bg-dark-2
	background-color: $dark-2
.bg-black
	background-color: $black
.bg-black-0
	background-color: $black0
.bg-black-1
	background-color: $black1
.color-white
	.slider__caption
		color: $white
	.slider__counter_current
		color: $white
	.slider__progressbar
		background-color: $brown-grey-2
	.slider__progressbar-fill
		background-color: $white
	.figure-property__headline
		background-color: $white
	.section-intro__headline
		background-color: $white
	.section__headline
		background-color: $white

//@for $i from 1 through 9
//	.bg-image-#{$i}
//		background-image: url('../img/assets/projects/bg-#{$i}.jpg')

div[class*='bg-image-']
	background-size: cover
	background-position: center center
