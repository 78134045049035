.slider-text
	width: 100%
	height: 100%
.slider-text__upper
	height: 50%
	.slider-text__slide
		align-items: flex-end
.slider-text__lower
	height: 50%
	.slider-text__slide
		align-items: flex-start
.slider-text__slide
	flex: 1 1 auto
	padding: 15px 0
	display: flex
	justify-content: center
	h2
		font-size: 54px
		font-weight: 600
		line-height: 1.59
		white-space: nowrap
		margin-bottom: 0
.slider-text__header
	position: relative
.slider-text__line
	width: 120px
	height: 2px
	background-color: $dark
	margin-left: 30px
	margin-right: 30px
.slider-text__counter
	position: absolute
	top: -15px
	right: 100%
	+small-caps
	color: $brown-grey-2
	white-space: nowrap
.slider-text__link
	display: flex
	align-items: center
	background-image: none
	&:hover
		opacity: 1
		color: $white
.slider-text__helper
	position: absolute
	left: 0
	right: 0
	bottom: 30px
	margin: 0 auto
	text-align: center	
.slider-text__helper-label-normal
	+small-caps
	margin: 0 50px
	padding-bottom: 2px
	overflow: hidden
.slider-text__helper-label-view
	position: absolute
	left: 0
	right: 0
	+small-caps
	padding-bottom: 2px
	overflow: hidden
.slider-text__helper__inner
	position: relative
	display: inline-flex
	align-items: center

@media screen and (max-width: $xxl)
	.slider-text__slide
		h2
			font-size: 43px
			
@media screen and (max-width: $md)
	.slider-text__slide
		h2
			font-size: 28px
			
@media screen and (max-width: $sm)
	.slider-text__slide
		text-align: center
		flex: 1 0 100%
		h2
			white-space: unset
			line-height: 1.3
	.slider-text__line
		display: none
	.slider-text__counter
		position: relative
		top: auto
		left: auto
		margin-bottom: 15px
		right: auto
