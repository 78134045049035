.gmap
	width: 100%
	height: 100%
	background-color: $light-blue-grey-2
.gmap__container
	width: 100%
	height: 100%
	height: 900px

@media only screen and (max-width: $md)
	.gmap__container
		height: 600px
		max-height: 120vh
