.smooth-scroll
	overflow: hidden
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
[data-os-animation]
	opacity: 0
	visibility: hidden
	.split-text__line
		a
			background-image: linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 1px, rgba(0,0,0,0) 100px)
[data-os-animation="animated"]
	.split-text__line
		a
			background-image: linear-gradient(to top, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, $brown-grey-2 0px, rgba(0,0,0,0) 1px, rgba(0,0,0,0) 100px)
